import { defineStore } from "pinia";
import {
  GET_USER_QUERY,
  GET_USER_SUSPENDED_REASON_QUERY,
} from "@/graphql/queries/UserQueries";
import Vue from "vue";
import { GetFromLocalStorage, SaveToLocalStorage } from "@/utils/localStorage";

const createPermission = (resource) => {
  return Array.isArray(resource)
    ? resource.some(
        (permission) =>
          permission === "create" || permission === "create-limited"
      )
    : false;
};

export const useUserStore = defineStore("user/user", {
  state: () => ({
    account: {},
    permissions: {},
    billingFeatureFlagEnabled: false,
    suspended: false,
    serverlessContainersLimitedAccess: true,
    subscriptionPending: false,
  }),
  getters: {
    /**
     * Checks if the user has 'create' or 'create-limited' permission for a given resource.
     * @returns {Function} - A function that takes a resource name and returns a boolean indicating if the user can create the resource.
     */
    userCanCreate: (state) => {
      return (resource) => {
        if (!state.billingFeatureFlagEnabled) {
          return true;
        }

        return createPermission(state.permissions[resource]);
      };
    },

    /**
     * Checks if the user is suspended.
     * @param state
     * @returns {Boolean} - A boolean indicating if the user is suspended.
     */
    isUserSuspended: (state) => {
      if (!state.billingFeatureFlagEnabled) {
        return false;
      }
      return state.suspended;
    },
  },
  actions: {
    /**
     * Fetches the user data, sets user permissions, and retrieves the billing feature flag.
     * If an error occurs, logs the user out and re-throws the error.
     * @async
     * @function getUser
     * @throws Will throw an error if the user data cannot be fetched.
     */
    async getUser() {
      try {
        this.billingFeatureFlagEnabled =
          await Vue.prototype.$featureFlag.isEnabled("billing");
        let response = await Vue.prototype.$query(
          GET_USER_QUERY(this.billingFeatureFlagEnabled)
        );
        this.account = response.data.account;
      } catch (error) {
        Vue.$keycloak.logout();
        throw error;
      }
    },

    isGranted(action, resource) {
      if (!this.billingFeatureFlagEnabled) {
        return true;
      }
      if (this.permissions[resource] === undefined) {
        return false;
      }

      return this.permissions[resource].some(
        (permission) => permission === action
      );
    },

    /**
     * Sets the user permissions by fetching them from the authorization service.
     * If an error occurs, clears the permissions and re-throws the error.
     * @async
     * @function setUserPermissions
     * @throws Will throw an error if the permissions cannot be fetched.
     */
    async setUserPermissions() {
      try {
        const permissions = await Vue.prototype.$getAuthorize();
        if (permissions.error !== undefined) {
          this.permissions = {};
          return;
        }
        permissions.forEach((permission) => {
          this.permissions[permission.rsname] = permission.scopes || null;
        });
      } catch (error) {
        this.permissions = {};
        throw error;
      } finally {
        this.userHasRestrictedAccess();
        this.userHasLimitedAccess("serverless-container");
      }
    },

    /**
     * Checks if the user has restricted access to the 'serverless-container' resource.
     * Sets the `suspended` state based on the user's permissions.
     */
    userHasRestrictedAccess() {
      this.suspended = !createPermission(
        this.permissions["serverless-container"]
      );
    },

    /**
     * Checks if the user has limited access to a given resource.
     * Sets the `serverlessContainersLimitedAccess` state based on the user's permissions.
     * @param {string} resource - The name of the resource to check.
     */
    userHasLimitedAccess(resource) {
      this.serverlessContainersLimitedAccess =
        !this.permissions[resource]?.includes("create");
    },

    getUserSuspendedReason() {
      if (!this.billingFeatureFlagEnabled) {
        return null;
      }
      try {
        const response = Vue.prototype.$query(GET_USER_SUSPENDED_REASON_QUERY);
        return response.data.account.suspendedReason;
      } catch (error) {
        return error;
      }
    },
    pollForSubscription() {
      this.subscriptionPending = true;

      SaveToLocalStorage(
        "serverless-containers-subscription-request-time",
        new Date().toISOString()
      );

      return new Promise((resolve, reject) => {
        let timeSubscribed = new Date(
          GetFromLocalStorage("serverless-containers-subscription-request-time")
        ).getTime();

        let intervalId = setInterval(async () => {
          await this.setUserPermissions().catch((error) => reject(error));

          // Update the condition
          let conditionMet =
            moreThanBufferTimePassed() ||
            this.isGranted("create", "serverless-container");

          // If condition is met, clear the interval
          if (conditionMet) {
            clearInterval(intervalId);
            this.subscriptionPending = false;
            resolve(this.isGranted("create", "serverless-container"));
          }
        }, 3000);

        function moreThanBufferTimePassed() {
          let currentTime = new Date().getTime();
          return (currentTime - timeSubscribed) / 1000 > 30;
        }
      });
    },
  },
});
