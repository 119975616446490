import { defineStore } from "pinia";

export const useSnackbarStore = defineStore('theme/snackbar', {
  state: () => ({
    snackbarToggleProperties: {
        isSnackbarOpen: false,
        isActionConfirmed: false,
    },
    snackbarPayload: {}
  }),
  actions: {
    toggleSnackbarVisibility(payload) {
        this.snackbarToggleProperties.isSnackbarOpen = !this.snackbarToggleProperties.isSnackbarOpen
        this.snackbarPayload = payload;
    },
    toggleActionComplete(payload) {
        this.snackbarToggleProperties.isActionConfirmed = payload
        this.snackbarToggleProperties.isSnackbarOpen = !payload
    },
  }
});

