/**
 * Saves a value to local storage.
 * @param {string} key - The key under which the value is to be stored.
 * @param {Date | string} value - The value to be stored.
 */
export const SaveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

/**
 * Retrieves a value from local storage.
 * @param {string} key - The key of the value to be retrieved.
 * @returns {string|false} The value retrieved from local storage.
 */
export const GetFromLocalStorage = (key) => {
  if (localStorage.getItem(key) === null) {
    return false;
  }
  return localStorage.getItem(key);
}

/**
 * Removes a value from local storage.
 * @param {string} key - The key of the value to be removed.
 */
export const RemoveFromLocalStorage = (key) => {
  localStorage.removeItem(key);
}

/**
 * Clears all values from local storage.
 */
export const ClearLocalStorage = () => {
  localStorage.clear();
}
