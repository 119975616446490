import Vue from "vue";
import { getCheckout, getUpdateBillingInfo } from "@/services/chargebee";
import { useSnackbarStore } from "@/stores/theme/snackbar";
import { useUserStore } from "@/stores/user/user";

const initOptions = {
  site: process.env.VUE_APP_CHARGEBEE_SITE
};

const _chargebee = window.Chargebee ? window.Chargebee.init(initOptions) : null;

const Plugin = {
  install(Vue) {
    Vue.prototype.$chargebee = _chargebee;

    Vue.prototype.$openServerlessContainersCheckout = function () {
      return new Promise((resolve, reject) => {
        this.$chargebee.openCheckout({
          hostedPage: function () {
            return getCheckout(
              process.env.VUE_APP_SERVERLESS_CONTAINERS_SUBSCRIPTION_PLAN_ID
            )
              .then((response) => response)
              .catch((error) => reject(error));
          },
          error: function (error) {
            reject(error);
          },
          success: () => {
            const userStore = useUserStore();
            const snackbarStore = useSnackbarStore();
            snackbarStore.toggleSnackbarVisibility({
              message: `Your subscription request to serverless containers has been received.`,
              color: "success",
            });
            userStore
              .pollForSubscription()
              .then((res) => {
                if (res === false) {
                  snackbarStore.toggleSnackbarVisibility({
                    message: `Your subscription request to serverless containers cannot be done at the moment, try again later.`,
                    color: "snackbarwarning",
                  });
                }
                resolve(res);
              })
              .catch((res) => {
                snackbarStore.toggleSnackbarVisibility({
                  message: `Something went wrong. Try again or contact customer support`,
                  color: "snackbarwarning",
                });

                reject(res);
              });
          },
          close: function () {
            resolve(null);
          },
        });
      });
    };

    Vue.prototype.$openSubscriberBillingDetails = function () {
      this.$chargebee.openCheckout({
        hostedPage: function () {
          return getUpdateBillingInfo()
            .then((response) => response)
            .catch((error) => {
              throw error;
            });
        },
      });
    };
  },
};

Vue.use(Plugin);

export default Plugin;
