import Vue from 'vue';

/**
 * Sends a POST request to the billing endpoint to get the checkout details for a specific plan.
 * @param {string} planId - The ID of the plan for which the checkout details are to be fetched.
 * @returns {Promise<Object>} A promise that resolves with the checkout details for the specified plan.
 */
export const getCheckout = async (planId) => {
  const response = await fetch(`${process.env.VUE_APP_ENDPOINT_BILLING}/subscriptions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Vue.$keycloak.token}`
    },
    body: JSON.stringify({
      planId: `${planId}`,
    })
  });

  return response.json();
};

export const getUpdateBillingInfo = async () => {
  const response = await fetch(`${process.env.VUE_APP_ENDPOINT_BILLING}/information/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Vue.$keycloak.token}`
    }
  });

  return response.json();
}
