import { gql } from '@apollo/client/core';

export const GET_USER_QUERY = (
    billingEnabled = false
  ) => {
    return gql`
      query getUser {
        account {
          id
          role
          email
          name
          customer {
            id
            ${
              billingEnabled
                ? `suspendedReason`
                : ``
            }
            recurringPayment
          }
        }
      }
    `;
  };

export const GET_USER_SUSPENDED_REASON_QUERY = gql`
  query getUser {
    account {
      customer {
        suspendedReason
      }
    }
  }
`;
