import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import Vue from "vue";
import "./keycloak";
import Router from "../router/index";

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_ENDPOINT_PLATFORM,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Vue.$keycloak.token;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 7,
    // eslint-disable-next-line no-unused-vars
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      // push users to the error page
      if (null !== graphQLErrors && null !== operation && null !== forward) {
        Router.push({ path: "/error-occurred" });
      }
    }
  }
);

const additiveLink = from([errorLink, retryLink, authLink.concat(httpLink)]);

const apolloClient = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

export default apolloClient;
