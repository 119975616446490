import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import octaviaKit from '@/plugins/octavia.kit'
import {createPinia, PiniaVuePlugin} from 'pinia'
import apolloClient from '@/plugins/apollo'
import * as Sentry from '@sentry/vue'
import {BrowserTracing} from '@sentry/tracing'
import {HttpClient as HttpClientIntegration} from "@sentry/integrations";
import './plugins'
import GitLabFF from '@/plugins/featureFlags'
import {useUserStore} from "@/stores/user/user";
import '@/plugins/keycloak'
import keycloakAuth from "@/plugins/keycloak-auth";
import VueGtm from '@gtm-support/vue2-gtm';
import graphql from "@/plugins/graphql";

Vue.config.productionTip = false

if (!process.env.RUNNING_TESTS) {
  // Sentry initialization code from main.js
  Sentry.init({
    Vue,
    release: process.env.VUE_APP_VERSION,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: [/^ChunkLoadError:/],
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router), // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }), new HttpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [[500, 599]],
    })], // trackComponents: true,
    // tracesSampleRate: 0.8,
    // replaysSessionSampleRate: 0.3,
    // replaysOnErrorSampleRate: 1.0,
    // sendDefaultPii: true,
  })
}

// Add Hotjar script if NODE_ENV is not 'dev'
if (process.env.NODE_ENV !== 'development') {
  const script = document.createElement('script');
  script.textContent = `
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments)
      };
      h._hjSettings = {hjid: 1434108, hjsv: 6};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  `;
  document.head.appendChild(script);
}

// Use Gitlab Feauture Flags
Vue.use(GitLabFF);

// Octavia Theme imports
Vue.use(octaviaKit)

// Connect Apollo GraphQL
Vue.use(apolloClient)
Vue.use(graphql)

// Connect Pinia
Vue.use(PiniaVuePlugin)
export const pinia = createPinia()

// Connect Keycloak Auth
Vue.use(keycloakAuth)

Vue.$keycloak
  .init({
    onLoad: 'check-sso'
  })
  .then((authenticated) => {
    if (!authenticated) {
      if (window.location.pathname === '/register') {
        Vue.$keycloak.createRegisterUrl({
          redirectUri: `https://${window.location.host}/compute/serverless-containers/namespaces`,
        }).then(url => {
          window.location.href = url;
        })
        return;
      }
      Vue.$keycloak.login();
    } else {
      const userStore = useUserStore(pinia);
      userStore.authenticated = authenticated;
      userStore.token = Vue.$keycloak.token;
      userStore.refreshToken = Vue.$keycloak.refreshToken;

      userStore.getUser().then(() => {

        Vue.use(VueGtm, {
          id: 'GTM-K4MZHP6',
          defer: false,
          compatibility: false,
          nonce: '2726c7f26c',
          enabled: true,
          debug: true,
          loadScript: true,
          vueRouter: router,
          ignoredViews: ['welcome'],
          trackOnNextTick: false,
        })

        new Vue({
          el: '#app', router, vuetify, pinia, apolloClient, render: h => h(App, {props: {keycloak: Vue.$keycloak}})
        })
      }).catch(() => {
        Vue.$keycloak.logout();
      })
    }

  }).catch(() => {
  // redirect to login page to re-initialize the Keycloak session
  Vue.$keycloak.login();
})

Vue.$keycloak.onTokenExpired = () => {
  Vue.$keycloak.updateToken(-1)
}
