import { themeConfig, themePreset } from "@/themeConfig";

import { defineStore } from "pinia";

export const useThemeConfigStore = defineStore('theme/themeConfig', {
    state: () => ({
        themeMode: themeConfig,
        vuetifyPreset: themePreset,
        isRestrictedWarningBannerVisible: false
    }),
    getters: {
        getThemeMode: (state) => state.themeMode,
        getVuetifyPreset: (state) => state.vuetifyPreset
    },

    actions: {
        toggleThemeFetchingState(payload) {
            this.themeMode.isFetching = payload;
        },
        toggleThemeLoadingState(payload) {
            this.themeMode.isLoading = payload;
        },
        toggleVerticalSidebarDrawer() {
            this.themeMode.isVerticalSidebarOpen = !this.themeMode.isVerticalSidebarOpen;
        },
        setNotFoundState(payload) {
            this.themeMode.isNotFound = payload;
        },
        showRestrictedWarningBanner(payload) {
            this.isRestrictedWarningBannerVisible = payload;
        }
    }


});
