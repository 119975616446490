import {UnleashClient} from "unleash-proxy-client";

class FeatureFlag {
  featureFlags = undefined;
  started = false;
  client = undefined;

  constructor(keycloak) {
    this.keycloak = keycloak
    this.host = process.env.VUE_APP_FF_HOST
    this.instanceId = process.env.VUE_APP_FF_INSTANCEID

    this.headers = {
      'UNLEASH-INSTANCEID': this.instanceId,
      'UNLEASH-APPNAME': process.env.VUE_APP_ENV
    }

    let config = {
      url: process.env.VUE_APP_FF_HOST,
      clientKey: 'cloud-portal',
      appName: "cloud-dashboard",
      disableMetrics: true,
      usePOSTrequests: true
    };

    this.client = new UnleashClient(config);
  }

  async fetchAll() {
    try {
      const headers = this.headers
      const res = await fetch(`${this.host}/features`, {headers});
      const data = await res.json();
      this.featureFlags = data.features;
    } catch (e) {
      throw new Error ('There was an error fetching the feature flags:', e)
    }
  }

  async start() {
    this.client.updateContext({ userId: this.keycloak.tokenParsed.email});
    this.client.on("initialized", () => {
      this.started = true;
    });
    this.client.start();
  }

  async isEnabled(featureName) {
    if (process.env.VUE_APP_FF_HOST.endsWith("/proxy")) {
      if (this.client.isReady()) {
        return this.client.isEnabled(featureName);
      }

      if (!this.started) {
        await this.start();
      }

      return this.client.isEnabled(featureName);
    }

    // TODO: Remove this when the proxy is in place
    if (this.featureFlags === undefined) {
      await this.fetchAll()
    }

    if (this.featureFlags.length === 0) {
      return false;
    }

    const featureFlag = this.featureFlags.find(featureFlag => featureFlag.name === featureName)

    if (featureFlag === undefined) {
      return false;
    }

    if (!featureFlag.enabled) {
      return false;
    }

    const results = [];

    for (const strategy of featureFlag.strategies) {
      if (strategy.name === "userWithId") {
        const userIds = strategy.parameters.userIds.trim().split(',');
        results.push(userIds.includes(this.keycloak.tokenParsed.email));
      } else {
        results.push(true);
      }
    }

    return results.includes(true);
  }

  async isDisabled(featureName) {
    return !await this.isEnabled(featureName)
  }
}


export default {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
      Vue.prototype.$featureFlag = new FeatureFlag(Vue.$keycloak)
    }
}

